//获取定位
$(function () {
  // console.log(!$.cookie('area'))
  getLocation()
})

$(document).on('click', '.logout-btn', function () {
  logout()
})

//地理位置
function getLocation() {
  //浏览器支持geolocation
  if (!$.cookie('area')) {
    var geolocation = new BMap.Geolocation()
    var longitude, latitude
    navigator.geolocation.getCurrentPosition(function (position) {
      //alert("1");
      longitude = position.coords.longitude
      latitude = position.coords.latitude
    })
    console.log(longitude, latitude)
    geolocation.getCurrentPosition(function (r) {
      if (r && r != null) {
        var adds = r.point
        var latitude = adds.lat,
          longitude = adds.lng

        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          $.ajax({
            async: false,
            url:
              'https://api.map.baidu.com/geocoder/v2/?ak=QgxsO8h5Y9VneeMTyGIYQB3DVZSzRTlQ&location=' +
              latitude +
              ',' +
              longitude +
              '&output=json',
            dataType: 'jsonp',
            callback: 'BMap._rd._cbk43398',
            success: function (res) {
              var result = res.result
              // console.log(result)
              data = result.addressComponent
              city = data.city
              adcode = data.adcode
              district = data.district
              var expiresDate = new Date()
              expiresDate.setTime(
                expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000
              )
              $.cookie('area', city, {
                path: '/',
                expires: expiresDate,
                // domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $.cookie('areaName', city, {
                path: '/',
                expires: expiresDate,
                // domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })

              $.cookie('areaId', adcode, {
                path: '/',
                expires: expiresDate,
                // domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $.cookie('areaProvince', city, {
                path: '/',
                expires: expiresDate,
                // domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $.cookie('areaDistrict', district, {
                path: '/',
                expires: expiresDate,
                // domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $.cookie('realArea', data.formatted_address, {
                path: '/',
                expires: expiresDate,
                domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $('#address-name-1').text($.cookie('areaName'))
              $('#address-name-2').text($.cookie('areaName'))
              if (typeof vm != 'undefined') {
                vm.areaValue =
                  $.cookie('areaProvince') + $.cookie('areaDistrict')
              }
            }
          })
        }
      } else {
        $('#address-name-1').text($.cookie('areaName'))
        $('#address-name-2').text($.cookie('areaName'))
      }
    })
  }
}

/*切换*/
function TabSelect(tab, con, addClass, obj) {
  var $_self = obj
  var $_nav = $(tab)
  $_nav.removeClass(addClass)
  $_self.addClass(addClass)
  var $_index = $_nav.index($_self)
  var $_con = $(con)
  $_con.hide()
  $_con.eq($_index).show()
}
$(function () {
  $('#clicktab .tabbtn li').click(function () {
    TabSelect('#clicktab .tabbtn li', '#clicktab .tabcon', 'current', $(this))
  })
  $('#clicktab .tabbtn li')
    .eq(0)
    .trigger('click')
})
/*导航滑动*/
$(function () {
  navTrigger()

  function navTrigger() {
    if ($(window).scrollTop() > 86) {
      $('#nav').show()
    } else {
      $('#nav').hide()
    }

    $('.inf-left').css('top', $(window).scrollTop() + 339 + 'px')
  }

  $(window).scroll(navTrigger)
})
// 登出
function logout() {
  $.post(
    '/logout/do',
    function (result) {
      if (result.code == '0') {
        // alert('退出成功')
        var expiresDate = new Date()
        // expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        //  删除小能客服用的 userCode
        $.cookie('userCode', '', {
          path: '/',
          expires: expiresDate,
          domain: document.domain.replace(/^[^\.]+\./, ''),
          secure: false
        })
        $.cookie('phone', '', {
          path: '/',
          expires: expiresDate,
          domain: document.domain.replace(/^[^\.]+\./, ''),
          secure: false
        })
        $.cookie('userId', '', {
          path: '/',
          expires: expiresDate,
          domain: document.domain.replace(/^[^\.]+\./, ''),
          secure: false
        })
        $.cookie('uname', '', {
          path: '/',
          expires: expiresDate,
          domain: document.domain.replace(/^[^\.]+\./, ''),
          secure: false
        })
        window.open('/', '_self')
      } else {
        alert(result.content ? result.content.msg : result.msg)
      }
    },
    'json'
  )
}

function busDialog() {
  var _busDialog = $('#busDialog')
  if (!_busDialog[0]) {
    var str = '<div id="busDialog">'
    str +=
      '<img src="/images/banner_01.png"/><span><img src="/images/busdialog/index-close.png"/></span>'
    str += '<div class="dialog-btn">'
    str +=
      '<a class="a1 cumService-btn" class="cumService-btn" href="javascript:void(0)">我要咨询</a>'
    str +=
      '<a class="a2" href="/transaction-quick" target="_blank">我要发布</a>'
    str += '</div>'
    str += '</div>'
    $('body').append(str)
    $('<style>')
      .prop('type', 'text/css')
      .html(
        '\
            #busDialog{\
                display:none;\
                position: fixed;\
                z-index: 1000;\
                top:50%;\
                left:50%;\
                -webkit-transform: translate(-50%, -50%);\
                transform: translate(-50%, -50%);\
            }\
            #busDialog img{\
                display:block;\
                width:624px;\
                height:363px;\
            }\
            #busDialog span{\
                position:absolute;\
                right: 10px;\
                top: 10px;\
                display:block;\
                width:46px;\
                height:46px;\
                cursor:pointer;\
            }\
            #busDialog .dialog-btn{\
                position:absolute;\
                left:19px;\
                bottom:51px;\
            }\
            #busDialog .dialog-btn .a1{\
                width: 110px;\
                height: 35px;\
                display: inline-block;\
                padding: 10px 0 0 34px;\
                margin-left: 40px;\
                background: url(/images/busdialog/btn_bg.png) no-repeat 10px center;\
                background-size: 22px 22px;\
                color: #fff;\
                background-color: #546aad;\
                border-radius: 6px;\
            }\
            #busDialog .dialog-btn .a1:hover{\
                background-color: #7488C7;\
            }\
            #busDialog .dialog-btn .a2{\
                width:110px;\
                height:35px;\
                display:inline-block;\
                padding:10px 0 0 30px;\
                background: url(/images/busdialog/fa_btn.png) no-repeat 10px center;\
                background-size: 22px 22px;\
                margin-left:50px;\
                border-radius: 6px;\
                background-color:#FF751A;\
                color:#fff;\
            }\
            #busDialog .dialog-btn .a2:hover{\
                background-color:#FF9852;\
            }\
            #busDialog span img{\
                display:block;\
                width:14px;\
                height:14px;\
                cursor:pointer;\
            }\
        '
      )
      .appendTo('head')
  }
  $('#busDialog span').on('click', function () {
    $(this)
      .parent()
      .fadeOut()
  })
  // setTimeout(function () {
  //   $('#busDialog').fadeIn()
  // }, 3000)
  // }, 30)
}
//订阅邮件
$(function () {
  $('.subscribe-box button').click(function () {
    var emailIpt = $(this).prev('input')
    var emailTxt = $.trim(emailIpt.val())
    checkSubscribleEmail(emailTxt)
  })
  $('#subscribeIpt').bind('keypress', function (event) {
    if (event.keyCode == '13') {
      checkSubscribleEmail($(this).val())
    }
  })

  function checkSubscribleEmail(emailTxt) {
    var mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
    if (!emailTxt) {
      toast('邮箱不能为空')
      return
    }
    if (!mailReg.test(emailTxt)) {
      toast('邮箱格式不正确')
      return
    }
    $.post(
      '/subscribeEmail',
      {
        email: emailTxt,
        platform: 5
      },
      function (result) {
        if (result.code == '0') {
          toast('邮箱订阅成功')
        }
      },
      'json'
    )
  }
})
